import React, { Component } from 'react';
import Hero from '../components/Hero';

export default class Home extends Component {
  render() {
    return (
      <div>
        <Hero />
      </div>
    );
  }
}
