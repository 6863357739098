import React from 'react';
import ProjectSection from '../components/ProjectSection';

export const Projects = () => {
  return (
    <div>
      <ProjectSection />
    </div>
  );
};
